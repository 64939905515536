import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fetchCases } from '../api/skins';

const CasesPageWrapper = styled.div`
  padding: 2rem;
  background-color: #1e2024;
  color: #ffffff;
`;

const CasesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

const CaseCard = styled(Link)`
  background-color: #2a2e35;
  border-radius: 4px;
  padding: 1rem;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CaseImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 1rem;
`;

const CaseName = styled.h3`
  font-size: 1rem;
  text-align: center;
`;

function CasesPage() {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    const loadCases = async () => {
      const casesData = await fetchCases();
      setCases(casesData);
    };
    loadCases();
  }, []);

  return (
    <CasesPageWrapper>
      <h1>Cases & Capsules</h1>
      <CasesGrid>
        {cases.map(caseItem => (
          <CaseCard key={caseItem.id} to={`/case/${caseItem.id}`}>
            <CaseImage src={caseItem.image} alt={caseItem.name} />
            <CaseName>{caseItem.name}</CaseName>
          </CaseCard>
        ))}
      </CasesGrid>
    </CasesPageWrapper>
  );
}

export default CasesPage;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import SkinPage from './pages/SkinPage';
import CasesPage from './pages/CasesPage';
import CaseDetailsPage from './pages/CaseDetailsPage';
import LoadingScreen from './components/LoadingScreen';
import { fetchInitialData } from './api/skins';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await fetchInitialData();
      } catch (error) {
        console.error('Failed to load initial data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadInitialData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/skin/:id" element={<SkinPage />} />
          <Route path="/cases" element={<CasesPage />} />
          <Route path="/case/:id" element={<CaseDetailsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
import axios from 'axios';

const API_BASE_URL = 'https://bymykel.github.io/CSGO-API/api/en';
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

let skinsData = [];
let collectionsData = [];
let weaponsData = [];
let casesData = [];

const getCachedData = (key) => {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_EXPIRATION) {
      return data;
    }
  }
  return null;
};

const setCachedData = (key, data) => {
  localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
};

export const fetchInitialData = async () => {
  try {
    const cachedSkins = getCachedData('skins');
    const cachedCollections = getCachedData('collections');
    const cachedCases = getCachedData('cases');

    if (cachedSkins && cachedCollections && cachedCases) {
      skinsData = cachedSkins;
      collectionsData = cachedCollections;
      casesData = cachedCases;
    } else {
      const [skinsResponse, collectionsResponse, casesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/skins.json`),
        axios.get(`${API_BASE_URL}/collections.json`),
        axios.get(`${API_BASE_URL}/crates.json`)
      ]);

      skinsData = skinsResponse.data.map(skin => ({
        ...skin,
        collection: skin.collection || { name: 'Uncategorized' }
      }));
      collectionsData = [
        { id: 'uncategorized', name: 'Uncategorized' },
        ...collectionsResponse.data
      ];
      casesData = casesResponse.data.filter(crate => crate.type === 'Case');

      setCachedData('skins', skinsData);
      setCachedData('collections', collectionsData);
      setCachedData('cases', casesData);
    }

    weaponsData = [...new Set(skinsData.map(skin => skin.weapon.name))].map(name => ({ name }));
  } catch (error) {
    console.error('Error fetching initial data:', error);
  }
};

export const fetchSkins = async (filters = {}, sortOption = 'release_date_desc', page = 1, limit = 20) => {
  let filteredSkins = [...skinsData];

  if (filters.collections && filters.collections.length > 0) {
    filteredSkins = filteredSkins.filter(skin => 
      skin.collection && 
      filters.collections.includes(skin.collection.name)
    );
  }
  if (filters.weapons && filters.weapons.length > 0) {
    filteredSkins = filteredSkins.filter(skin => filters.weapons.includes(skin.weapon.name));
  }

  filteredSkins.sort((a, b) => {
    switch (sortOption) {
      case 'release_date_desc':
        return new Date(b.release_date || 0) - new Date(a.release_date || 0);
      case 'price_desc':
        return (b.price || 0) - (a.price || 0);
      case 'price_asc':
        return (a.price || 0) - (b.price || 0);
      case 'name_asc':
        return a.name.localeCompare(b.name);
      case 'name_desc':
        return b.name.localeCompare(a.name);
      default:
        return 0;
    }
  });

  const startIndex = (page - 1) * limit;
  const paginatedSkins = filteredSkins.slice(startIndex, startIndex + limit);

  return {
    items: paginatedSkins,
    total: filteredSkins.length
  };
};

export const fetchSkinDetails = async (id) => {
  const skin = skinsData.find(skin => skin.id === id);
  
  if (skin) {
    skin.prices = {
      'Factory New': (Math.random() * 100 + 100).toFixed(2),
      'Minimal Wear': (Math.random() * 80 + 80).toFixed(2),
      'Field-Tested': (Math.random() * 60 + 60).toFixed(2),
      'Well-Worn': (Math.random() * 40 + 40).toFixed(2),
      'Battle-Scarred': (Math.random() * 20 + 20).toFixed(2),
    };

    skin.priceHistory = Array.from({ length: 30 }, (_, i) => ({
      date: new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      price: Math.random() * 100 + 50,
    }));

    skin.recentSales = Array.from({ length: 10 }, (_, i) => ({
      id: i + 1,
      float: Math.random().toFixed(4),
      price: (Math.random() * 100 + 50).toFixed(2),
      date: new Date(Date.now() - i * 60 * 60 * 1000).toISOString(),
    }));
  }

  return skin || null;
};

export const fetchCases = async () => {
  return casesData;
};

export const fetchCollections = async () => {
  return collectionsData;
};

export const fetchWeapons = async () => {
  return weaponsData;
};

export const searchSkins = async (query) => {
  return skinsData.filter(skin => 
    skin.name.toLowerCase().includes(query.toLowerCase()) ||
    skin.weapon.name.toLowerCase().includes(query.toLowerCase())
  );
};

export const fetchCaseDetails = async (id) => {
  const caseItem = casesData.find(c => c.id === id);
  if (!caseItem) {
    return null;
  }

  const caseSkins = skinsData.filter(skin => 
    caseItem.contains_rare && skin.rarity.name === 'Rare' ||
    caseItem.contains.some(item => item.id === skin.id)
  );

  return {
    ...caseItem,
    skins: caseSkins
  };
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FilterSidebar.css';

function FilterSidebar({ onFilterChange, collections, weapons, selectedCollections, selectedWeapons }) {
  const [collectionSearch, setCollectionSearch] = useState('');
  const [weaponSearch, setWeaponSearch] = useState('');

  const handleCollectionChange = (collection) => {
    const updatedCollections = selectedCollections.includes(collection)
      ? selectedCollections.filter(c => c !== collection)
      : [...selectedCollections, collection];
    onFilterChange({ collections: updatedCollections, weapons: selectedWeapons });
  };

  const handleWeaponChange = (weapon) => {
    const updatedWeapons = selectedWeapons.includes(weapon)
      ? selectedWeapons.filter(w => w !== weapon)
      : [...selectedWeapons, weapon];
    onFilterChange({ collections: selectedCollections, weapons: updatedWeapons });
  };

  const filteredCollections = collections.filter(collection =>
    collection.name.toLowerCase().includes(collectionSearch.toLowerCase())
  );

  const filteredWeapons = weapons.filter(weapon =>
    weapon.name.toLowerCase().includes(weaponSearch.toLowerCase())
  );

  return (
    <aside className="sidebar-wrapper">
      <div className="filter-section">
        <h3 className="filter-title">Collections</h3>
        <input
          className="search-input"
          type="text"
          placeholder="Search collections..."
          value={collectionSearch}
          onChange={(e) => setCollectionSearch(e.target.value)}
        />
        <ul className="filter-list">
          {filteredCollections.map(collection => (
            <li key={collection.id || collection.name} className="filter-item">
              <label className="filter-label">
                <input
                  type="checkbox"
                  className="filter-checkbox"
                  checked={selectedCollections.includes(collection.name)}
                  onChange={() => handleCollectionChange(collection.name)}
                />
                <span className="filter-name">{collection.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="filter-section">
        <h3 className="filter-title">Weapons</h3>
        <input
          className="search-input"
          type="text"
          placeholder="Search weapons..."
          value={weaponSearch}
          onChange={(e) => setWeaponSearch(e.target.value)}
        />
        <ul className="filter-list">
          {filteredWeapons.map(weapon => (
            <li key={weapon.name} className="filter-item">
              <label className="filter-label">
                <input
                  type="checkbox"
                  className="filter-checkbox"
                  checked={selectedWeapons.includes(weapon.name)}
                  onChange={() => handleWeaponChange(weapon.name)}
                />
                <span className="filter-name">{weapon.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

FilterSidebar.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  collections: PropTypes.array.isRequired,
  weapons: PropTypes.array.isRequired,
  selectedCollections: PropTypes.array.isRequired,
  selectedWeapons: PropTypes.array.isRequired,
};

export default FilterSidebar;
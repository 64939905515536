import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchSkinDetails } from '../api/skins';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SkinPageWrapper = styled.div`
  padding: 2rem;
  background-color: #1e2024;
  color: #ffffff;
  min-height: 100vh;
`;

const SkinHeader = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const SkinImageContainer = styled.div`
  position: relative;
  width: 300px;
  flex-shrink: 0;
`;

const SkinImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  background-color: #2a2e35;
  border-radius: 4px;
`;

const SkinRarityBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: ${props => props.color};
  color: #ffffff;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
`;

const SkinInfo = styled.div`
  flex: 1;
  min-width: 300px;
`;

const SkinName = styled.h1`
  margin-bottom: 1rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const SkinProperty = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

const FloatBarContainer = styled.div`
  margin: 1rem 0;
  position: relative;
`;

const FloatBar = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`;

const FloatSegment = styled.div`
  height: 100%;
  background-color: ${props => props.color};
  width: ${props => props.width}%;
`;

const FloatIndicator = styled.div`
  width: 2px;
  height: 20px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: ${props => props.$position}%;
  transform: translateX(-50%);
`;

const FloatLabels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #8f9296;
  margin-top: 0.5rem;
`;

const FloatValue = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
`;

const AdditionalInfo = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

const InfoCard = styled.div`
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 4px;
`;

const InfoTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #8f9296;
`;

const InfoValue = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
`;

const PriceSection = styled.div`
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1rem;
`;

const PriceHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`;

const PriceCard = styled.div`
  background-color: #3a3f48;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
`;

const PriceLabel = styled.p`
  font-size: 0.8rem;
  color: #8f9296;
  margin-bottom: 0.5rem;
`;

const PriceValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const ChartSection = styled.div`
  margin-top: 2rem;
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 4px;
`;

const RecentSalesSection = styled.div`
  margin-top: 2rem;
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 4px;
`;

const SalesList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SaleItem = styled.li`
  background-color: #3a3f48;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StatTrakBadge = styled.span`
  background-color: #CF6A32;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  margin-left: 0.5rem;
`;

function SkinPage() {
  const { id } = useParams();
  const [skin, setSkin] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]);
  const [recentSales, setRecentSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadSkinDetails = async () => {
      try {
        setLoading(true);
        const skinData = await fetchSkinDetails(id);
        if (skinData) {
          setSkin(skinData);
          setPriceHistory(skinData.priceHistory || []);
          setRecentSales(skinData.recentSales || []);
        } else {
          setError('Skin not found');
        }
      } catch (err) {
        console.error('Error loading skin details:', err);
        setError('Failed to load skin details');
      } finally {
        setLoading(false);
      }
    };
    loadSkinDetails();
  }, [id]);

  if (loading) {
    return <SkinPageWrapper>Loading...</SkinPageWrapper>;
  }

  if (error) {
    return <SkinPageWrapper>Error: {error}</SkinPageWrapper>;
  }

  if (!skin) {
    return <SkinPageWrapper>Skin not found</SkinPageWrapper>;
  }

  const chartData = {
    labels: priceHistory.map(entry => entry.date),
    datasets: [
      {
        label: 'Price History',
        data: priceHistory.map(entry => entry.price),
        fill: false,
        borderColor: '#3d85c6',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#ffffff',
        },
      },
      title: {
        display: true,
        text: 'Price History (Last 30 Days)',
        color: '#ffffff',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#8f9296',
        },
        grid: {
          color: '#2a2e35',
        },
      },
      y: {
        ticks: {
          color: '#8f9296',
        },
        grid: {
          color: '#2a2e35',
        },
      },
    },
  };

  const getFloatColor = (float) => {
    if (float <= 0.07) return '#4b69ff'; // Factory New
    if (float <= 0.15) return '#8847ff'; // Minimal Wear
    if (float <= 0.38) return '#d32ce6'; // Field-Tested
    if (float <= 0.45) return '#eb4b4b'; // Well-Worn
    return '#ffd700'; // Battle-Scarred
  };

  const getFloatPercentage = (float) => {
    return ((float - skin.min_float) / (skin.max_float - skin.min_float) * 100).toFixed(2);
  };

  return (
    <SkinPageWrapper>
      <SkinHeader>
        <SkinImageContainer>
          <SkinImage src={skin.image} alt={skin.name} />
          <SkinRarityBadge color={skin.rarity.color}>{skin.rarity.name}</SkinRarityBadge>
        </SkinImageContainer>
        <SkinInfo>
          <SkinName>
            {skin.name}
            {skin.stattrak && <StatTrakBadge>StatTrak™</StatTrakBadge>}
          </SkinName>
          <SkinProperty>Weapon: {skin.weapon?.name || 'Unknown'}</SkinProperty>
          <SkinProperty>Collection: {skin.collection?.name || 'Unknown'}</SkinProperty>
          <SkinProperty>Finish Style: {skin.finish_style || 'Unknown'}</SkinProperty>
          {skin.min_float !== undefined && skin.max_float !== undefined && (
            <FloatBarContainer>
              <FloatBar>
                <FloatSegment color="#4b69ff" width={7} />
                <FloatSegment color="#8847ff" width={8} />
                <FloatSegment color="#d32ce6" width={23} />
                <FloatSegment color="#eb4b4b" width={7} />
                <FloatSegment color="#ffd700" width={55} />
                <FloatIndicator $position={getFloatPercentage((skin.min_float + skin.max_float) / 2)} />
              </FloatBar>
              <FloatLabels>
                <span>FN</span>
                <span>MW</span>
                <span>FT</span>
                <span>WW</span>
                <span>BS</span>
              </FloatLabels>
              <FloatValue>
                Float Range: {skin.min_float.toFixed(4)} - {skin.max_float.toFixed(4)}
              </FloatValue>
            </FloatBarContainer>
          )}
          <AdditionalInfo>
            <InfoCard>
              <InfoTitle>Pattern Index</InfoTitle>
              <InfoValue>{skin.pattern_index || 'N/A'}</InfoValue>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Paint Index</InfoTitle>
              <InfoValue>{skin.paint_index || 'N/A'}</InfoValue>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Added to Game</InfoTitle>
              <InfoValue>{skin.added_date ? new Date(skin.added_date).toLocaleDateString() : 'N/A'}</InfoValue>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Tradable</InfoTitle>
              <InfoValue>{skin.tradable ? 'Yes' : 'No'}</InfoValue>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Marketable</InfoTitle>
              <InfoValue>{skin.marketable ? 'Yes' : 'No'}</InfoValue>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Commodity</InfoTitle>
              <InfoValue>{skin.commodity ? 'Yes' : 'No'}</InfoValue>
            </InfoCard>
          </AdditionalInfo>
        </SkinInfo>
      </SkinHeader>
      <PriceSection>
        <PriceHeader>Current Prices</PriceHeader>
        <PriceGrid>
          {Object.entries(skin.prices || {}).map(([wear, price]) => (
            <PriceCard key={wear}>
              <PriceLabel>{wear}</PriceLabel>
              <PriceValue>${price}</PriceValue>
            </PriceCard>
          ))}
        </PriceGrid>
      </PriceSection>
      {priceHistory.length > 0 && (
        <ChartSection>
          <div style={{ height: '400px' }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </ChartSection>
      )}
      {recentSales.length > 0 && (
        <RecentSalesSection>
          <PriceHeader>Recent Sales</PriceHeader>
          <SalesList>
            {recentSales.map(sale => (
              <SaleItem key={sale.id}>
                <span>Float: {sale.float}</span>
                <span>${sale.price}</span>
                <span>{new Date(sale.date).toLocaleString()}</span>
              </SaleItem>
            ))}
          </SalesList>
        </RecentSalesSection>
      )}
    </SkinPageWrapper>
  );
}

export default SkinPage;
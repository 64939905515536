import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { searchSkins } from '../api/skins';
import './Header.css';

function Header() {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm.length >= 3) {
        const results = await searchSkins(searchTerm);
        setSuggestions(results.slice(0, 5));
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
      setSuggestions([]);
    }
  };

  return (
    <header className="header-wrapper">
      <Link to="/">
        <img className="logo" src="/logo.png" alt="Proseetings Skins" />
      </Link>
      <div className="search-bar-wrapper">
        <form onSubmit={handleSearch}>
          <input
            className="search-bar"
            type="text"
            placeholder="Search skins..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="search-icon" onClick={handleSearch} />
        </form>
        {suggestions.length > 0 && (
          <div className="search-suggestions">
            {suggestions.map((skin) => (
              <Link key={skin.id} to={`/skin/${skin.id}`} className="suggestion-item">
                <img className="suggestion-image" src={skin.image} alt={skin.name} />
                {skin.name}
              </Link>
            ))}
          </div>
        )}
      </div>
      <nav className="nav">
        <Link to="/skins" className="nav-link">Skins</Link>
        <Link to="/cases" className="nav-link">Cases</Link>
        <Link to="/statistics" className="nav-link">Statistics</Link>
      </nav>
    </header>
  );
}

export default Header;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchCaseDetails } from '../api/skins';
import SkinCard from '../components/SkinCard';

const CaseDetailsPageWrapper = styled.div`
  padding: 2rem;
  background-color: #1e2024;
  color: #ffffff;
`;

const CaseHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const CaseImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-right: 2rem;
`;

const CaseInfo = styled.div`
  flex: 1;
`;

const CaseName = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const SkinsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

function CaseDetailsPage() {
  const { id } = useParams();
  const [caseDetails, setCaseDetails] = useState(null);

  useEffect(() => {
    const loadCaseDetails = async () => {
      const details = await fetchCaseDetails(id);
      setCaseDetails(details);
    };
    loadCaseDetails();
  }, [id]);

  if (!caseDetails) {
    return <CaseDetailsPageWrapper>Loading...</CaseDetailsPageWrapper>;
  }

  return (
    <CaseDetailsPageWrapper>
      <CaseHeader>
        <CaseImage src={caseDetails.image} alt={caseDetails.name} />
        <CaseInfo>
          <CaseName>{caseDetails.name}</CaseName>
          <p>{caseDetails.description}</p>
        </CaseInfo>
      </CaseHeader>
      <h2>Skins in this case:</h2>
      <SkinsGrid>
        {caseDetails.skins.map(skin => (
          <SkinCard key={skin.id} skin={skin} />
        ))}
      </SkinsGrid>
    </CaseDetailsPageWrapper>
  );
}

export default CaseDetailsPage;
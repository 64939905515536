import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import SkinCard from '../components/SkinCard';
import FilterSidebar from '../components/FilterSidebar';
import { fetchSkins, fetchCollections, fetchWeapons } from '../api/skins';
import Pagination from '../components/Pagination';

const HomePageWrapper = styled.div`
  display: flex;
  background-color: #1e2024;
  color: #ffffff;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
`;

const SkinGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
`;

const SortingOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SortSelect = styled.select`
  padding: 0.5rem;
  background-color: #2a2e35;
  color: #ffffff;
  border: 1px solid #3a3f48;
  border-radius: 4px;
  font-size: 0.875rem;
`;

const ResultCount = styled.span`
  font-size: 0.875rem;
  color: #8f9296;
`;

const LoadMoreButton = styled.button`
  background-color: #3d85c6;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 0.875rem;

  &:hover {
    background-color: #2a6aa4;
  }
`;

function HomePage() {
  const [skins, setSkins] = useState([]);
  const [filters, setFilters] = useState({ collections: [], weapons: [] });
  const [sortOption, setSortOption] = useState('release_date_desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [collections, setCollections] = useState([]);
  const [weapons, setWeapons] = useState([]);
  const itemsPerPage = 20;

  const loadSkins = useCallback(async () => {
    const result = await fetchSkins(filters, sortOption, currentPage, itemsPerPage);
    setSkins(result.items);
    setTotalCount(result.total);
    setTotalPages(Math.ceil(result.total / itemsPerPage));
  }, [filters, sortOption, currentPage]);

  useEffect(() => {
    loadSkins();
  }, [loadSkins]);

  useEffect(() => {
    const loadCollections = async () => {
      const collectionsData = await fetchCollections();
      setCollections(collectionsData);
    };
    loadCollections();
  }, []);

  useEffect(() => {
    const loadWeapons = async () => {
      const weaponsData = await fetchWeapons();
      setWeapons(weaponsData);
    };
    loadWeapons();
  }, []);

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  }, []);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <HomePageWrapper>
      <FilterSidebar 
        onFilterChange={handleFilterChange} 
        collections={collections} 
        weapons={weapons}
        selectedCollections={filters.collections}
        selectedWeapons={filters.weapons}
      />
      <MainContent>
        <SortingOptions>
          <ResultCount>{totalCount} items</ResultCount>
          <SortSelect value={sortOption} onChange={handleSortChange}>
            <option value="release_date_desc">Newest</option>
            <option value="price_desc">Price: High to Low</option>
            <option value="price_asc">Price: Low to High</option>
            <option value="name_asc">Name: A to Z</option>
            <option value="name_desc">Name: Z to A</option>
          </SortSelect>
        </SortingOptions>
        <SkinGrid>
          {skins.map((skin) => (
            <SkinCard key={skin.id} skin={skin} />
          ))}
        </SkinGrid>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </MainContent>
    </HomePageWrapper>
  );
}

export default HomePage;
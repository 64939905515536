import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SkinCard.css';

function SkinCard({ skin }) {
  return (
    <Link to={`/skin/${skin.id}`} className="card">
      <img className="skin-image" src={skin.image} alt={skin.name} />
      <div className="skin-info">
        <h3 className="skin-name">{skin.name}</h3>
        <span className="skin-rarity" style={{ color: skin.rarity.color }}>{skin.rarity.name}</span>
        <span className="skin-weapon">{skin.weapon.name}</span>
      </div>
    </Link>
  );
}

SkinCard.propTypes = {
  skin: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    rarity: PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
    weapon: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SkinCard;
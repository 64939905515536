import React from 'react';
import './LoadingScreen.css';

function LoadingScreen() {
  return (
    <div className="loading-wrapper">
      <img className="logo" src="/logo.png" alt="Prosettings Logo" />
      <div className="spinner"></div>
    </div>
  );
}

export default LoadingScreen;